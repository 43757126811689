<template>
  <div class="jrebelCSS-flex-col page">
    <div class="jrebelCSS-flex-col jrebelCSS-justify-start jrebelCSS-items-start jrebelCSS-relative section">
      <div class="group">
        <span class="font_2 text text_4">J</span>
        <span class="font_1 text text_3">Rebel&amp;</span>
        <span class="font_2 text">X</span>
        <span class="text_5 text text_2">Rebel在线激活</span>
      </div>
      <img class="jrebelCSS-shrink-0 image pos" src="../../img/82d8d7b7a3dd6f8b1d47635bbd19107c.png" />
    </div>
    <div class="jrebelCSS-flex-row jrebelCSS-justify-center jrebelCSS-items-center section_2 space-x-80">
      为了方便记得住，我们将地址更为：jrebel.com.cn，欢迎大家来白嫖~
    </div>
    <div class="jrebelCSS-flex-row jrebelCSS-justify-center jrebelCSS-items-center section_2 space-x-80">
      <!-- <img class="image_2" src="../../img/7b562526739b639adab0c320712ddd8b.png" /> -->
      <div class="jrebelCSS-flex-col jrebelCSS-items-start jrebelCSS-items-center group_2 space-y-36">
        <div class="group_3">
          <span class="font_2 text text_7">J</span>
          <span class="font_1 text">Rebel&amp;</span>
          <span class="font_2 text">X</span>
          <span class="text_5 text">Rebel激活版本：</span>
        </div>
        <div class="group_4">
          <span class="font_3 text_8">包含所有新旧版本</span>
          <span class="font_3 text_9">，包括2023.4.2、2023.4.1、2023.4.0、2023.3.2、2023.3.1、2023.3.0、2023.2.2、2023.2.1、2023.2.0、2023.1.2、2023.1.1
等以及所有2022版本</span>
        </div>
      </div>
    </div>
    <div class="jrebelCSS-flex-col section_1 space-y-80">
      <div class="jrebelCSS-flex-col jrebelCSS-justify-start group_1">
        <div class="jrebelCSS-flex-col jrebelCSS-justify-start jrebelCSS-items-center group_6">
          <div>
            <span class="font_2 text text_15">J</span>
            <span class="font_1 text">Rebel&amp;</span>
            <span class="font_2 text">X</span>
            <span class="text_5 text">Rebel激活服务器地址</span>
          </div>
        </div>
      </div>
      <div class="jrebelCSS-flex-col jrebelCSS-justify-start jrebelCSS-items-start">
        <div class="jrebelCSS-flex-col jrebelCSS-justify-start group_7">
          <div class="jrebelCSS-flex-col jrebelCSS-justify-start group_8">
            <div class="jrebelCSS-flex-col jrebelCSS-justify-start group_8">
              <div class="jrebelCSS-flex-col space-y-24">
                <div class="jrebelCSS-flex-col space-y-12">
                  <span class="jrebelCSS-self-start font_5 text_16">激活服务器地址（路线1,推荐）</span>
                  <div class="jrebelCSS-flex-col jrebelCSS-justify-start jrebelCSS-items-start text-wrapper_1">
                    <span class="font_6 text_1">{{activeUrlOne}}</span>
                  </div>
                </div>
                <div class="jrebelCSS-flex-col space-y-12">
                  <span class="jrebelCSS-self-start font_5 text_16">激活服务器地址（路线2）</span>
                  <div class="jrebelCSS-flex-col jrebelCSS-justify-start jrebelCSS-items-start text-wrapper_1">
                    <span class="font_6 text_1">{{activeUrlTwo}}</span>
                  </div>
                </div>
                <div class="jrebelCSS-flex-col space-y-12">
                  <span class="jrebelCSS-self-start font_5 text_16">激活服务器地址（路线3）</span>
                  <div class="jrebelCSS-flex-col jrebelCSS-justify-start jrebelCSS-items-start text-wrapper_1">
                    <span class="font_6 text_1">{{activeUrlThree}}</span>
                  </div>
                </div>
                <div class="jrebelCSS-flex-col space-y-6">
                  <span class="jrebelCSS-self-start font_5">激活邮箱</span>
                  <div class="jrebelCSS-flex-col jrebelCSS-justify-start jrebelCSS-items-start text-wrapper_2">
                    <span class="font_6 text_14">{{activeEmail}}</span>
                  </div>
                </div>
                <div>
                  <a href="https://support.qq.com/product/617366" target="_blank" style="font-size: smaller;">不能用了？告诉我</a>
                </div>
                <div>
                  <a href="https://www.zhihu.com/question/40854395/answer/3253446837" target="_blank" style="font-size: smaller">这个网站的部署过程，去看看</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="jrebelCSS-flex-col section_3 space-y-322">
      <div class="jrebelCSS-flex-row jrebelCSS-justify-between group_5">
        <img class="image_4" src="../../img/372bea23336e4eaaf2ca1329ebcf71b.jpg" />
        <span class="jrebelCSS-self-start text_10">维护服务器不易，蚂蚁的腿也是肉，嘿嘿~</span>
      </div>
      <div class="jrebelCSS-flex-col jrebelCSS-justify-start jrebelCSS-items-center text-wrapper">
        <span class="font_5 text_11">©2023 产品版权所有。保留所有权利。
          <a href="https://beian.miit.gov.cn/" target="_blank">琼ICP备2023010356号-1</a>
          &nbsp;&nbsp;&nbsp;通过邮件联系我: <a href="mailto:jrebelpro@yeah.net">jrebelpro@yeah.net</a>
        </span>
        
      </div>
    </div>
  </div>
</template>

<script>

import { v4 as uuidv4 } from 'uuid';
import { usePush } from 'vue-baidu-analytics'


  export default {
    components: {},
    props: {},
    data() {
      return {
        activeUrlOne: '',
        activeUrlTwo: '',
        activeUrlThree: '',
        activeEmail: '请输入激活邮箱',
        baidu: usePush()

      };
    },

    methods: {
      generateRandomEmail() {
      const domains = ["gmail.com", "yahoo.com", "hotmail.com", "outlook.com"];
      const randomDomain = domains[Math.floor(Math.random() * domains.length)];
      
      const usernameLength = Math.floor(Math.random() * 10) + 5; // 随机生成 5 到 14 个字符的用户名
      let username = "";
      const possibleChars = "abcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < usernameLength; i++) {
        const randomIndex = Math.floor(Math.random() * possibleChars.length);
        username += possibleChars.charAt(randomIndex);
      }

      return `${username}@${randomDomain}`;
    },
    genrateActiveUrlOne() {
      const uuid = uuidv4();
      const url = `https://jrebel.com.cn/${uuid}`;
      return url;
    },
    genrateActiveUrlTwo() {
        const uuid = uuidv4();
        const url = `https://jrebel.idoudou.me/${uuid}`;
        return url;
      },
      genrateActiveUrlThree() {
        const uuid = uuidv4();
        const url = `http://42.193.18.168:8088/${uuid}`;
        return url;
      },
     
    },

    mounted() {
      // this.$clipboard = useClipboard;

      //设置 activeUrl值 
      this.activeUrlOne = this.genrateActiveUrlOne();
      this.activeUrlTwo = this.genrateActiveUrlTwo();
      this.activeUrlThree = this.genrateActiveUrlThree();
      //随机生成邮箱
      this.activeEmail = this.generateRandomEmail();

      // this.pushGoogleEvent();
      // 通过钩子变量去触发方法
      this.baidu.pv('/jrebel/');
    },
  };
</script>

<style scoped lang="css">
  .page {
    background-color: rgb(255, 255, 255);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
  .section {
    padding: 5.5rem 0 5.63rem;
    background-color: rgb(29, 33, 41);
    overflow: hidden;
  }
  .group {
    margin-left: 5rem;
    line-height: 1.42rem;
  }
  .font_2 {
    font-size: 1.5rem;
    font-family: HarmonyOSSansSC;
    line-height: 1.09rem;
    font-weight: 700;
    color: rgb(165, 214, 63);
  }
  .font_1 {
    font-size: 1.5rem;
    font-family: HarmonyOSSansSC;
    line-height: 1.17rem;
    font-weight: 700;
    color: rgb(29, 33, 41);
  }
  .text_5 {
    font-size: 1.5rem;
    font-family: HarmonyOSSansSC;
    line-height: 1.41rem;
    font-weight: 700;
    color: rgb(29, 33, 41);
  }
  .text {
    word-break: break-all;
  }
  .text_3 {
    color: rgb(255, 255, 255);
  }
  .text_4 {
    line-height: 1.11rem;
  }
  .text_2 {
    color: rgb(255, 255, 255);
    line-height: 1.42rem;
  }
  .image {
    width: 25rem;
    height: 12.5rem;
  }
  .pos {
    position: absolute;
    right: 2.09rem;
    bottom: 0;
  }
  .section_2 {
    padding: 2.5rem;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
  }
  .space-x-80 > *:not(:first-child) {
    margin-left: 2.5rem;
  }
  .image_2 {
    border-radius: 0.5rem;
    width: 17.78rem;
    height: 18.97rem;
  }
  .group_2 {
    width: 30.77rem;
  }
  .space-y-36 > *:not(:first-child) {
    margin-top: 1.13rem;
  }
  .group_3 {
    line-height: 1.41rem;
  }
  .text_7 {
    line-height: 1.11rem;
  }
  .group_4 {
    line-height: 0.56rem;
  }
  .font_3 {
    font-size: 0.56rem;
    font-family: HarmonyOSSansSC;
    line-height: 0.56rem;
  }
  .text_8 {
    color: rgb(165, 214, 63);
    line-height: 0.53rem;
  }
  .text_9 {
    color: rgb(29, 33, 41);
  }
  .section_1 {
    padding: 2.5rem 0% 11rem 0%;  /* 改这里 */
    background-color: rgb(255, 255, 255);
    overflow: hidden;
  }
  .space-y-80 > *:not(:first-child) {
    margin-top: 2.5rem;
  }
  .group_1 {
    height: 1.75rem;
    margin: 0 auto;
  }
  .group_6 {
    padding: 0.13rem 0 0.75rem;
  }
  .text_15 {
    line-height: 1.11rem;
  }
  .group_7 {
    margin-left: -4.31rem;
    width: 31.16rem;
    height: 10.09rem;
    margin: 0 auto;
  }
  .group_8 {
    width: 31.16rem;
  }
  .space-y-24 > *:not(:first-child) {
    margin-top: 0.75rem;
  }
  .space-y-12 > *:not(:first-child) {
    margin-top: 0.38rem;
  }
  .font_5 {
    font-size: 0.94rem;
    font-family: HarmonyOSSansSC;
    line-height: 0.88rem;
    color: rgb(29, 33, 41);
  }
  .text_16 {
    line-height: 0.86rem;
  }
  .text-wrapper_1 {
    padding: 1rem 0;
    background-color: rgb(242, 243, 245);
    border-radius: 0.38rem;
    width: 31.31rem;
  }
  .font_6 {
    font-size: 0.8rem;
    font-family: HarmonyOSSansSC;
    line-height: 0.47rem;
    color: rgb(134, 144, 156);
  }
  .text_1 {
    margin-left: 0.38rem;
  }
  .space-y-6 > *:not(:first-child) {
    margin-top: 0.19rem;
  }
  .text-wrapper_2 {
    padding: 1rem 0;
    background-color: rgb(242, 243, 245);
    border-radius: 0.38rem;
    width: 31.22rem;
  }
  .text_14 {
    margin-left: 0.38rem;
  }
  .section_3 {
    padding: 3.97rem 2.5rem 0;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
  }
  .space-y-322 > *:not(:first-child) {
    margin-top: 10.06rem;
  }
  .group_5 {
    padding: 0 1.63rem;
    margin: 0 auto;
  }
  .image_4 {
    width: 14.31rem;
    height: 19.34rem;
  }
  .text_10 {
    margin-right: 0.63rem;
    margin-top: 9.75rem;
    color: rgb(165, 214, 63);
    font-size: 1rem;
    font-family: HarmonyOSSansSC;
    font-weight: 700;
    line-height: 1.05rem;
  }
  .text-wrapper {
    padding: 0.88rem 0 1.38rem;
    height: 3.16rem;
    border-top: solid 0.031rem rgb(201, 205, 212);
  }
  .text_11 {
    line-height: 0.89rem;
  }

</style>