import Vue from 'vue';
import App from './app.vue';
import router from './router';
import baiduAnalytics from "vue-baidu-analytics";

Vue.config.productionTip = false;

Vue.use(baiduAnalytics, {
  router: router,
  siteIdList: ['995021dbf4581453e6cde145dba48753'], //在上面生成的script中hm.src后面的字符串
  isDebug: false,
});

const app = new Vue({
  router,
  render: (h) => h(App),
})



app.$mount('#app');